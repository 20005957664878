

/* General Styles */
.local-seo-container {
  font-family: Arial, sans-serif;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  margin-top: 6.6rem;
}

h1, h2 {
  color: var(--text-color);
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

/* Banner Section */
.local-seo-banner {
  background-color: var(--background-color);
  padding: 50px 0;
  text-align: center;
}

.local-seo-banner h1 {
  font-size: 48px;
  font-weight: bold;
  color: var(--primary-color);
}

.local-seo-banner p {
  font-size: 20px;
  color: var(--secondary-color);
  margin-top: 10px;
}

/* About Local SEO Section */
.local-seo-about {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  background-color: #fff;
}

.local-seo-about-text {
  flex: 1;
  padding-right: 20px;
}

.local-seo-about-text p {
  font-size: 18px;
  line-height: 1.6;
  color: var(--secondary-color);
  text-align: justify;
}

.local-seo-about-image {
  flex: 1;
  padding-left: 20px;
}

.local-seo-about-image img {
  display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Ranking Factors and How-To Section */
.local-seo-content {
  background-color: var(--background-color);
  padding: 50px;
}

.local-seo-flex-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.local-seo-text {
  width: 48%;
}

.local-seo-text ul {
  list-style-type: disc;
  margin-left: 20px;
}

.local-seo-text li {
  font-size: 16px;
  line-height: 1.6;
  color: var(--secondary-color);
}

/* Additional Section */
.local-seo-extra {
  background-color: var(--primary-color);
  padding: 50px;
  text-align: center;
  color: #fff;
}

.local-seo-extra h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.local-seo-extra p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
}

.local-seo-btn {
  background-color: #fff;
  color: var(--primary-color);
  font-size: 18px;
  padding: 12px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.local-seo-btn:hover {
  background-color: var(--button-hover-color);
  color: #fff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .local-seo-about {
    flex-direction: column;
    text-align: center;
  }

  .local-seo-about-text,
  .local-seo-about-image {
    flex: 1 1 100%;
    padding: 0;
  }

  .local-seo-flex-container {
    flex-direction: column;
  }

  .local-seo-text {
    width: 100%;
    margin-bottom: 20px;
  }

  .local-seo-btn {
    padding: 15px 35px;
  }
}
