
  
  .googleads-page {
    font-family: Arial, sans-serif;
    color: var(--text-color);
    background-color: var(--background-color);
    padding: 20px;
    margin-top: 4rem;
    width: 100%;
  }
  
  /* Banner Section */
  .googleads-banner {
    text-align: center;
    padding: 60px 20px;
    background-color: var(--primary-color);
    color: white;
    width: 100%;
    margin-bottom: 30px;
  }
  
  .googleads-banner h1 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .googleads-banner p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  /* Text and Image Section */
  .googleads-text-image {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin: 30px 0;
  }
  
  .googleads-text {
    flex: 1;
    min-width: 300px;
    padding: 10px;
  }
  
  .googleads-text h2 {
    text-align: center;
    color: var(--primary-color);
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  .googleads-text p {
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .googleads-text ul {
    margin: 10px 0;
    padding-left: 20px;
    list-style: disc;
  }
  
  .googleads-image {
    flex: 1;
    min-width: 300px;
    text-align: center;
  }
  
  .googleads-image img {
    max-width: 80%; /* Adjusts width */
    max-height: 300px; /* Adjusts height */
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }
  
  /* Why Choose Section */
  .googleads-whychoose-title {
    text-align: center;
    color: var(--primary-color);
  }
  
  .googleads-whychoose-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 30px 0;
  }
  
  .googleads-whychoose-item {
    background-color: var(--primary-color);
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .googleads-whychoose-item h3 {
    color: white;
    margin-top: 10px;
    font-size: 1.5rem;
  }
  
  /* Manager Accounts Section */
  .googleads-manager-title {
    text-align: center;
    color: var(--primary-color);
  }
  
  .googleads-manager-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 30px 0;
  }
  
  .googleads-manager-item {
    background-color: var(--primary-color);
    color: white;
    padding: 20px;
    border-radius: 10px;
  }
  
  .googleads-manager-item h3 {
    text-align: center;
  
  }
  
  .googleads-manager-item ul {
    margin: 10px 0;
    padding-left: 20px;
    list-style: disc;
  }
  
  /* Additional Section */
  .googleads-additional-section {
    background-color: var(--primary-color);
    padding: 30px;
    margin: 30px 0;
    border-radius: 10px;
  }
  
  .googleads-additional-section h2 {
    text-align: center;
    color: white;
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  .googleads-additional-content p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .googleads-additional-content ul {
    margin: 10px 0;
    padding-left: 20px;
    list-style: disc;
  }
  
  /* Call to Action Section */
  .googleads-cta {
    text-align: center;
    margin: 40px 0;
    padding: 20px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 10px;
  }
  
  .googleads-cta button {
    background-color: white;
    color: var(--primary-color);
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .googleads-banner h1 {
      font-size: 2.5rem;
    }
  
    .googleads-banner p {
      font-size: 1rem;
    }
  
    .googleads-text-image {
      flex-direction: column;
      text-align: center;
    }
  
    .googleads-text,
    .googleads-image {
      margin-bottom: 20px;
    }
  
    .googleads-image img {
      max-width: 100%;
      max-height: 250px; /* Further reduce height on smaller screens */
    }
  
    .googleads-whychoose-grid,
    .googleads-manager-grid {
      grid-template-columns: 1fr;
    }
  }
  