body {
  background:  #E8DCF4;
}


:root {
  --primary-color: #3681AB;
  --secondary-color: #4b5154;
  --accent-color: #999BBE;
  --background-color: #E8DCF4;
  --text-color: #1d1727;
  --border-color: #CDC6E7;
  --button-hover-color: #AED7ED; /* Slightly brighter for hover */
  --sectiontittle:#1E3A8A
}