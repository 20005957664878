/* Navbar2 Styles */
.navbar2 {
  background-color: var(--primary-color); /* Main Background */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: top 0.3s;
}

.navbar2.hidden {
  top: -100px; /* Hide the navbar */
}

.navbar2.visible {
  top: 0; /* Show the navbar */
}

.contact-info {
  display: flex;
  align-items: center;
  color: white;
}

.contact-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.contact-item a {
  color: white; /* Button Text color */
  text-decoration: none;
  margin-left: 8px;
}

.contact-item i {
  color: white; /* Button Text color */
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icons a {
  margin-left: 20px;
  color: white; /* Button Text color */
  font-size: 20px;
  text-decoration: none;
}

.social-icons a:hover {
  color: white; /* Hover Text color */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .navbar2 {
    flex-direction: row; /* Ensure both sections stay in one row */
    justify-content: space-between; /* Spread out left and right content */
  }

  .contact-info {
    margin: 0;
    justify-content: flex-start; /* Align contact items to the left */
  }

  .social-icons {
    margin: 0;
    justify-content: flex-end; /* Align social icons to the right */
  }

  .contact-item a {
    display: none; /* Hide email and phone text on small screens */
  }

  .contact-item i {
    font-size: 18px; /* Adjust icon size */
  }

  .social-icons a {
    font-size: 18px; /* Adjust icon size for smaller screens */
  }
}
