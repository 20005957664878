

/* Navbar Styles */
.navbar {
  background-color: #000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: right 0.5s ease, top 0.5s ease;
  margin-top: 3.7rem;
}

.navbar.visible {
  right: 0;
  top: 0;
}

.navbar.scrolled {
  right: 0;
  top: 0;
}

/* Navbar Container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.logo-gif {
  width: 190px;
  height: 80px;
  display: block;
  object-fit: contain;
  margin-left: -70px;
} 

.nav-links {
  display: flex;
  list-style: none;
  gap: 2rem;
}

.nav-item {
  position: relative;
  
}

.nav-link {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  color: var(--background-color);
  transition: color 0.3s;
}

.nav-link:hover {
  color: var(--primary-color);
  text-decoration: none;

}
.mega-menu-left {
  width: 30%;
  background-color: var(--primary-color);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  border-right: 2px solid var(--border-color);
}

.mega-menu-left img {
  width: 100%;
  height: 300px;
  max-width: 300px;
  clip-path: polygon(50% 0%, 90% 30%, 90% 100%, 10% 100%, 10% 30%); /* Home/House shape */
  background-color: var(--accent-color); /* Adds a fallback background if image fails to load */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.mega-menu-left img:hover {
  transform: scale(1.05);
}

.mega-menu-left p {
  font-size: 1rem;
  margin: 0;
}

/* Menu Right Section */
.mega-menu-right {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem;
  background: linear-gradient(135deg, #f4f7fc, #e8dcf4); /* Subtle gradient background */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
}

/* Icons with Hover Animation and Different Colors */
.menu-column h4 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 2rem; /* Larger font size for icons */
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--primary-color);

}

.menu-column:nth-child(1) h4 {
  color: #e63946; /* Red for first column */
}

.menu-column:nth-child(2) h4 {
  color: #457b9d; /* Blue for second column */
}

.menu-column:nth-child(3) h4 {
  color: #2a9d8f; /* Green for third column */
}

.menu-column:nth-child(4) h4 {
  color: #f4a261; /* Orange for fourth column */
}

.menu-column:nth-child(5) h4 {
  color: #e76f51; /* Salmon for fifth column */
}

.menu-column h4:hover {
  transform: scale(1.2);
  background-color: var(--button-hover-color);
  border-radius: 4px;
  padding: 5px;
  
}

/* Submenu Links with L Design */
.menu-column ul li {
  font-size: 1rem;
  margin-bottom: 1rem;
  position: relative;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.menu-column ul li::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  width: 10px;
  height: 100%;


  transition: background-color 0.3s ease;
}

.menu-column ul li a {
  text-decoration: none;
  color: inherit;
  padding-left: 15px; /* Adjust to align text with L-shape */
  display: inline-block;
}







.mega-menu-left p {
  font-size: 1rem;
  margin: 0;
}

.menu-column h4 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

/* Mega Menu */
.mega-menu {
  position: fixed;
  top: 9rem;
  left: 0;
  width: 100vw;
  height: 70vh;
  background-color: var(--background-color);
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  
}

.mega-menu-left {
  width: 30%;
  background-color: var(--primary-color);
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mega-menu-left img {
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  object-fit: contain;
}

.mega-menu-right {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem;
  gap: 2rem;
}

.menu-column {
  flex: -2 0 22%;
  margin-bottom: 1rem;
}

.menu-column h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.menu-column ul {
  list-style: none;
  padding: 0;
}

.menu-column ul li {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.menu-column ul li a {
  text-decoration: none;
  color: inherit;
}

.menu-column ul li a:hover {
  color: var(--primary-color);
  
}

/* Contact Button */
.contact-button {
  background-color: var(--primary-color);
  color: #ffffff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: var(--button-hover-color);
}

/* Responsive Design */
.menu-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .menu-icon {
    display: block;
    font-size: 2rem;
    cursor: pointer;
    color: white;
  }
  .mega-menu {
    position: fixed;
    top: 140px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--background-color);
    display: flex;
    z-index: 1000;
    flex-wrap: nowrap;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .mega-menu-left {
   display: none;
  }
  .mega-menu-right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2rem;
    background: linear-gradient(135deg, #f4f7fc, #e8dcf4); /* Subtle gradient background */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 2rem;
    max-height: 85vh;
    overflow: auto;

  }
  
  .nav-links.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 91px;
    right: 0px;
    width: 100%;
    height: 50vh;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background: var(--primary-color);
    color: var(--primary-color);
    padding: 140px;
}
.navbar {
  background-color: #000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: right 0.5s ease, top 0.5s ease;
  margin-top: 2.7rem;
}
}
/* Mega Menu Updates */
