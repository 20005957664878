
  
  /* Services Menu */
  .price-services-menu {
    padding: 15px 20px;
    background: var(--background-color);
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping of menu items */
    margin-top: 6rem;
  }
  
  .price-services-title {
    margin: 0 0 15px;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .price-services-list {
    display: flex;
    flex-wrap: nowrap; /* No wrapping for list items */
    gap: 30px;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .price-service-item {
    flex: 0 0 auto; /* Prevent shrinking */
    cursor: pointer;
    color: var(--primary-color);
    background: #fff;
    text-align: center;
    padding: 10px 20px;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .price-service-item:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  /* Plans Section */
  .price-service-section {
    margin-top: 30px;
  }
  
  .price-section-title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  /* Plan Cards */
  .price-plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 12px;
  }
  
  .price-card {
    background: #fff;
    border-radius: 10px;
    border: 2px solid var(--border-color);
    text-align: center;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .price-card:hover {
    transform: translateY(-5px);
  }
  
  .price-triangle {
    width: 100%;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 50px;
    margin: auto;
  }
  
  .price-triangle-text {
    position: relative;

    font-size: 1.2rem;
    font-weight: bold;
    color: white;
  }
  
  /* Plan Features */
  .plan-features {
    list-style: none;
    padding: 10px;
    margin: 0;
    text-align: left; /* Align text to the left */
  }
  
  .plan-feature {
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 0.9rem;
  }
  
  .plan-feature .feature-icon {
    margin-right: 10px;
    color: var(--primary-color);
  }
  
  /* Button Styling */
  .choose-plan-button {
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 15px;
    margin-bottom: 1rem;
  }
  
  .choose-plan-button:hover {
    background: var(--button-hover-color);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .price-services-list {
      overflow-x: scroll;
    }
  
    .price-service-item {
      min-width: 150px;
    }
  
    .price-plans {
      grid-template-columns: 1fr;
    }
  }
  /* Scroll to top button */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px; /* Positioned on the right */
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.scroll-to-top:hover {
  background-color: var(--button-hover-color);
}

/* Plan section title styling */
.price-section-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: var(--sectiontittle);

  text-decoration: none;
}
