/* CSS for Facebook Marketing Page */
.facebook-marketing-page {
    font-family: Arial, sans-serif;
    color: var(--secondary-color);
    background-color: var(--background-color);
    margin-top: 6rem;
  }
  
  /* Banner Section */
  .facebook-marketing-page-banner {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    padding: 50px;
  }
  
  .facebook-marketing-page-banner h1 {
    font-size: 36px;
  }
  
  .facebook-marketing-page-banner p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .facebook-banner-input {
    margin-top: 20px;
  }
  
  .facebook-banner-input input {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .facebook-banner-input button {
    padding: 10px 20px;
    background-color: var(--button-hover-color);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .facebook-banner-input button:hover {
    background-color: var(--primary-color);
  }
  
  /* Text and Image Section */
  .facebook-marketing-page-text-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
  }
  
  .facebook-text-image-text {
    width: 50%;
  }
  
  .facebook-text-image-text h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .facebook-text-image-text p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .facebook-text-image-text ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .facebook-text-image-text li {
    margin-bottom: 5px;
  }
  
  .facebook-text-image-image img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
  }
  
  /* Why Choose Section */
  .facebook-marketing-page-why-choose {
    background-color: var(--text-color);
    color: white;
    padding: 40px;
    text-align: center;
  }
  
  .facebook-marketing-page-why-choose h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .facebook-whychoose-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .facebook-whychoose-item {
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .facebook-whychoose-item img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .facebook-whychoose-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .facebook-whychoose-item p {
    font-size: 14px;
  }
  
  /* Case Studies Section */
  .facebook-marketing-page-case-studies {
    padding: 40px;
  }
  
  .facebook-marketing-page-case-studies h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .facebook-case-studies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .facebook-case-study-card {
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .facebook-case-study-card img {
    width: 100%;
  }
  
  .facebook-case-study-card h3 {
    font-size: 18px;
    margin: 10px;
  }
  
  .facebook-case-study-card p {
    font-size: 14px;
    margin: 5px 10px;
  }
  
  /* Call to Action Section */
  .facebook-marketing-page-cta {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    padding: 50px;
  }
  
  .facebook-marketing-page-cta h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .facebook-marketing-page-cta p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .facebook-marketing-page-cta button {
    padding: 10px 20px;
    background-color: var(--button-hover-color);
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .facebook-marketing-page-cta button:hover {
    background-color: var(--primary-color);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .facebook-marketing-page-text-image {
      flex-direction: column;
      align-items: center;
    }
  
    .facebook-text-image-text {
      width: 100%;
      text-align: center;
    }
  
    .facebook-text-image-image img {
      width: 100%;
    }
  
    .facebook-marketing-page-cta button {
      font-size: 16px;
      padding: 10px 25px;
    }
  
    .facebook-services-grid {
      grid-template-columns: 1fr 1fr; /* 2 cards per row on medium screens */
    }
  
    .facebook-service-item,
    .facebook-team-item {
      padding: 15px;
    }
  
    .facebook-case-studies-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .facebook-services-grid {
      grid-template-columns: 1fr; /* 1 card per row on smaller screens */
    }
  }
  
  /* Facebook Marketing Services Section */
  .facebook-marketing-page-services {
    padding: 50px;
    color: black;
    text-align: center;
  }
  
  .facebook-marketing-page-services h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .facebook-marketing-page-services p {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .facebook-services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Display 3 cards in a row */
    gap: 20px;
  }
  
  .facebook-service-item {
    background-color: var(--primary-color);
    color: var(--text-color);
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .facebook-service-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: var(--background-color);
  }
  
  .facebook-service-item p {
    font-size: 14px;
  }
  
  /* Facebook Marketing Team Section */
  .facebook-marketing-page-team {
    padding: 50px;
    color: black;
    text-align: center;
  }
  
  .facebook-marketing-page-team h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  
  .facebook-team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three cards in a row for larger screens */
    gap: 20px;
  }
  
  .facebook-team-item {
    color: var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .facebook-team-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  .facebook-team-item p {
    font-size: 14px;
    line-height: 1.6;
  }
  
  /* Responsive Design for Team */
  @media (max-width: 768px) {
    .facebook-marketing-page-team h2 {
      font-size: 24px;
    }
  
    .facebook-team-grid {
      grid-template-columns: 1fr 1fr; /* 2 cards per row on medium screens */
    }
  
    .facebook-team-item {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .facebook-team-grid {
      grid-template-columns: 1fr; /* 1 card per row on smaller screens */
    }
  }
  .facebook-whychoose{
    color: var(--primary-color);
    text-align: center;
  }