
  
  .franchise-marketing {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: Arial, sans-serif;
    margin-top: 6rem;
  }
  
  /* Banner Section */
  .franchise-marketing-banner {
    background-color: var(--primary-color);
    color: white;
    padding: 50px 20px;
    text-align: center;
  }
  
  .franchise-marketing-banner-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .franchise-marketing-banner-description {
    font-size: 1.2rem;
  }
  
  /* One Side Text, One Side Image */
  .franchise-marketing-content {
    display: flex;
    justify-content: space-between;
    padding: 50px 20px;
    flex-wrap: wrap;
  }
  
  .franchise-marketing-text {
    flex: 1;
    padding-right: 20px;
  }
  
  .franchise-marketing-content-title {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .franchise-marketing-content-description {
    font-size: 1.2rem;
    color: var(--secondary-color);
    text-align: justify;
  }
  
  .franchise-marketing-image {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .franchise-marketing-image-img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Our Franchise Customers Section */
  .franchise-marketing-customers {
    background-color: var(--accent-color);
    padding: 50px 20px;
  }
  
  .franchise-marketing-customers-title {
    font-size: 2rem;
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .franchise-marketing-customer-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .franchise-marketing-customer-item {
    background-color: var(--background-color);
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    text-align: center;
    width: 250px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .franchise-marketing-customer-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .franchise-marketing-customer-title {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .franchise-marketing-customer-description {
    font-size: 1rem;
    color: var(--secondary-color);
  }
  
  /* Call to Action */
  .franchise-marketing-cta {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    padding: 50px 20px;
  }
  
  .franchise-marketing-cta-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .franchise-marketing-cta-button {
    background-color: white;
    color: var(--primary-color);
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .franchise-marketing-cta-button:hover {
    background-color: var(--button-hover-color);
  }
  
  /* Additional Information Section */
  .franchise-marketing-more-info {
    padding: 50px 20px;
  }
  
  .franchise-marketing-more-info-title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .franchise-marketing-more-info-description {
    font-size: 1.2rem;
    color: var(--secondary-color);
    text-align: center;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .franchise-marketing-content {
      flex-direction: column;
      align-items: center;
    }
  
    .franchise-marketing-text,
    .franchise-marketing-image {
      flex: none;
      margin-bottom: 30px;
      text-align: center;
    }
  
    .franchise-marketing-customers {
      padding: 30px 10px;
    }
  
    .franchise-marketing-customer-item {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .franchise-marketing-cta-button {
      padding: 12px 25px;
    }
  }
  