:root {
    /* Color Palette */
    --primary-bg: #d8ddea;   /* Main Background */
    --header-bg: #18434e;   /* Header/Footer Background */
    --text-color: #051615;  /* Primary Text */
    --secondary-text: #78949f; /* Secondary Text */
    --button-bg: #18434e;   /* Default Button */
    --button-hover-bg: #051615; /* Hover Button */
    --button-text: #d8ddea; /* Button Text */
    --hover-text: #a1bdd0;  /* Hover Text */
    --border-color: #a1bdd0; /* Section/Card Borders */
  }
  
  .testimonials-container {
    text-align: center;
    padding: 40px 20px;
    background-color: var(--primary-bg);
  }
  
  .testimonials-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--header-bg);
  }
  
  .testimonials-description {
    font-size: 1rem;
    color: var(--secondary-text);
    margin-bottom: 40px;
  }
  
  .testimonials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .testimonial-card {
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
  }
  
  .testimonial-name {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 5px;
  }
  
  .testimonial-title {
    font-size: 0.875rem;
    color: var(--secondary-text);
    margin-bottom: 15px;
  }
  
  .testimonial-feedback {
    font-size: 0.875rem;
    color: var(--text-color);
    font-style: italic;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .testimonials-title {
      font-size: 1.5rem;
    }
  
    .testimonials-description {
      font-size: 0.9rem;
    }
  
    .testimonial-card {
      padding: 15px;
    }
  
    .testimonial-feedback {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .testimonials-title {
      font-size: 1.2rem;
    }
  
    .testimonials-description {
      font-size: 0.8rem;
    }
  
    .testimonial-feedback {
      font-size: 0.75rem;
    }
  }
  