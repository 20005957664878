
  
  .androiddevelopment-container {
    font-family: Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 20px;
    margin-top: 8rem;
  }
  
  /* Section 1: Banner Section */
  .androiddevelopment-banner {
    text-align: center;
    margin-bottom: 30px;
    justify-content: center;
    justify-items: center;
  }
  
  .androiddevelopment-banner h1 {
    color: var(--primary-color);
    font-size: 2rem;
  }
  
  .androiddevelopment-banner p {
    margin: 10px auto;
    max-width: 800px;
    line-height: 1.6;
  }
  
  /* Section 2: Benefits Section */
  .androiddevelopment-benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .androiddevelopment-box {
    flex: 1;
    max-width: 500px;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
  }
  
  .green-box {
    background-color: #e6f7e6;
    border-left: 6px solid #28a745;
  }
  
  .orange-box {
    background-color: #ffe6d4;
    border-left: 6px solid #ff6600;
  }
  
  .androiddevelopment-box h3 {
    color: var(--secondary-color);
    font-size: 1.2rem;
  }
  
  .androiddevelopment-box ul {
    margin-top: 10px;
    padding-left: 20px;
  }
  
  .androiddevelopment-box li {
    margin-bottom: 8px;
    line-height: 1.4;
  }
  
  /* Section 3: Features Section */
  .androiddevelopment-features {
    text-align: center;
    margin: 40px 0;
  }
  
  .androiddevelopment-features h2 {
    color: var(--primary-color);
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .androiddevelopment-features p {
    color: var(--secondary-color);
    margin-bottom: 30px;
  }
  
  /* Responsive Grid Layout */
  .androiddevelopment-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  
  @media (max-width: 1024px) {
    .androiddevelopment-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .androiddevelopment-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .grid-item {
    text-align: center;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(50px);
    opacity: 0;
    animation: fadeInUp 0.5s ease forwards;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .grid-item:hover {
    transform: translateY(0) scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon Styling */
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #fff;
  }
  
  .custom-app-icon {
    background-color: #4caf50;
  }
  
  .ui-ux-icon {
    background-color: #2196f3;
  }
  
  .performance-icon {
    background-color: #ff9800;
  }
  
  .integration-icon {
    background-color: #e91e63;
  }
  
  .maintenance-icon {
    background-color: #673ab7;
  }
  
  .scalability-icon {
    background-color: #009688;
  }
  
  /* Process Section */
  .androiddevelopment-process {
    padding: 40px 20px;
    background-color: var(--background-color);
    text-align: center;
  }
  
  .process-stages {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  
  .measure-line {
    text-align: center;
    justify-content: center;
    justify-items: center;
    margin-top: 1rem;
  }
  
  .stage {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 1024px) {
    .process-stages {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .process-stages {
      grid-template-columns: 1fr;
    }
  }
  
  /* Animation */
  @keyframes fadeInUp {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  /* General */
.androiddevelopment-container {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  margin-top: 8rem;
  text-align: justify;
}

/* Responsive Grid for Features and Process */
.androiddevelopment-grid,
.process-stages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Text Alignment */
.androiddevelopment-banner p,
.androiddevelopment-box ul,
.androiddevelopment-features p,
.process-stages p {
  text-align: justify;
}
