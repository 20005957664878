:root {
  --primary-color: #3681ab;
  --secondary-color: #4b5154;
  --accent-color: #999bbe;
  --background-color: #e8dcf4;
  --text-color: #1d1727;
  --border-color: #cdc6e7;
  --button-hover-color: #aed7ed;
}

/* Counter Section Container */
.counter-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3rem 5%;
  background: var(--background-color);
  font-family: "Arial", sans-serif;
}

/* Counter Item */
.counter-item {
  text-align: center;
  margin: 0 1rem;
  position: relative;
}

/* Counter Icons */
.counter-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  animation: fadeIn 1s ease-in-out;
}

/* Counter Value */
.counter-value {
  font-size: 3rem;
  font-weight: bold;
  color: var(--secondary-color);
  margin: 0;
  animation: fadeIn 1s ease-in-out;
}

.plus {
  font-size: 1.5rem;
  color: var(--accent-color);
  margin-left: 5px;
}

/* Counter Title */
.counter-title {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-top: 0.5rem;
}

/* Hover Effect */
.counter-item:hover .counter-value {
  color: var(--accent-color);
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.counter-item:hover .counter-icon {
  transform: scale(1.2);
  color: var(--button-hover-color);
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.counter-item:hover .counter-title {
  color: var(--primary-color);
  transition: color 0.3s ease-in-out;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .counter-section {
    flex-direction: column;
  }

  .counter-item {
    margin-bottom: 2rem;
  }
}
