:root {
  --primary-color: #3681AB;
  --secondary-color: #4b5154;
  --accent-color: #999BBE;
  --background-color: #E8DCF4;
  --text-color: #1d1727;
  --border-color: #CDC6E7;
  --button-hover-color: #AED7ED;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.about-section-container {
  padding: 20px;
}

/* Button Section */
.button-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.portfolio-button,
.success-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.success-button {
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.portfolio-button:hover,
.success-button:hover {
  background-color: var(--button-hover-color);
  color: var(--text-color);
}

/* Content Section */
.content-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
  flex-wrap: wrap;
}

.image-container {
  flex: 1;
  min-width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.text-container {
  flex: 1;
  min-width: 300px;
  background-color: var(--secondary-color);
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.text-container h2 {
  margin-bottom: 15px;
  font-size: 2rem;
  color: white;
}

.text-container p {
  margin-bottom: 10px;
  line-height: 1.8;
  font-size: 1.1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .button-section {
    flex-direction: column;
    align-items: center;
  }

  .content-section {
    flex-direction: column;
    text-align: center;
  }

  .team-image {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  .text-container {
    text-align: center;
  }

  .text-container h2 {
    font-size: 1.5rem;
  }

  .text-container p {
    font-size: 1rem;
  }
}
