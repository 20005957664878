/* Section Wrapper */
.serviceshome-section {
  padding: 4rem 1rem;
  background-color: var(--background-color);
  text-align: center;
  position: relative;
  margin-bottom: 2.5rem;
}

/* Section Heading and Description */
.serviceshome-header {
  margin-bottom: 2rem;
}

.serviceshome-header-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.serviceshome-header-description {
  font-size: 1.1rem;
  font-family: sans-serif;
  color: var(--secondary-color);
  text-align: justify;
}

/* Services Container */
.serviceshome-container {
  position: relative;
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
}

/* Center Image */
.center-image img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: contain;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 10;
  background-color: white;
}

/* Cards */
.card {
  position: absolute;
  width: 250px;
  height: 200px;
  background-color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: var(--text-color);
  text-align: center;
  transition: transform 0.3s ease;
}

.card .icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 0rem;
}

.card-title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.card-description {
  font-size: 0.9rem;
  color: var(--secondary-color);
}

/* Circular Animation */
@keyframes rotateCards {
  0% {
    transform: rotate(0deg) translateY(-300px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateY(-300px) rotate(-360deg);
  }
}

/* Animation for Each Card */
.card-1 {
  animation: rotateCards 12s linear infinite;
  transform-origin: center;
}

.card-2 {
  animation: rotateCards 12s linear infinite;
  animation-delay: 2s;
  transform-origin: center;
}

.card-3 {
  animation: rotateCards 12s linear infinite;
  animation-delay: 4s;
  transform-origin: center;
}

.card-4 {
  animation: rotateCards 12s linear infinite;
  animation-delay: 6s;
  transform-origin: center;
}

.card-5 {
  animation: rotateCards 12s linear infinite;
  animation-delay: 8s;
  transform-origin: center;
}

.card-6 {
  animation: rotateCards 12s linear infinite;
  animation-delay: 10s;
  transform-origin: center;
}

/* Stop Animation and Use Flex for Small Devices */
@media screen and (max-width: 768px) {
  .serviceshome-container {
    flex-direction: column;
    height: auto;
  }
  .serviceshome-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
   
  }
  .center-image img {
    width: 150px;
    height: 150px;
  }

  .card {
    position: relative;
    margin: 1rem auto;
    animation: none;
    width: 80%;
    height: auto;
  }

  .card .icon {
    font-size: 2rem;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .card-description {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .serviceshome-header-title {
    font-size: 2rem;
  }

  .serviceshome-header-description {
    font-size: 1rem;
    text-align: justify;
  }

  .card {
    width: 90%;
    padding: 1rem;
  }

  .center-image img {
    width: 120px;
    height: 120px;
  }
}
