.CustomDesign-App {
    text-align: justify;
}

.CustomDesign-banner {
    background-color: var(--primary-color);
    color: white;
    padding: 2rem 1rem;
    margin-top: 5.5rem;
    text-align: center;
}

.CustomDesign-banner h1 {
    margin: 0;
}

.CustomDesign-content {
    padding: 2rem 1rem;
}

.CustomDesign-section {
    margin-bottom: 2rem;
}

.CustomDesign-flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.CustomDesign-flex-item {
    flex: 1 1 45%;
    margin: 1rem;
}

.CustomDesign-illustration img {
    display: block;
    margin: 0 auto; /* Center horizontally */
    max-width: 100%;
    height: auto;
    border: 1px solid var(--border-color);
    border-radius: 8px;
}

.CustomDesign-services {
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 2rem;
    margin: 1rem auto;
}

.CustomDesign-services h2 {
    margin-bottom: 1.5rem;
    color: var(--primary-color);
}

.CustomDesign-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.CustomDesign-service-item {
    background-color: var(--background-color);
    padding: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CustomDesign-service-item h3 {
    margin-top: 0;
    color: var(--secondary-color);
    text-align: center;
}

.CustomDesign-service-item p {
    margin: 0.5rem 0 0;
    text-align: center;
}

.CustomDesign-process {
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 2rem;
    margin: 2rem 0;
}

.CustomDesign-process h2 {
    margin-bottom: 1.5rem;
    color: var(--primary-color);
}

.CustomDesign-process-step {
    margin: 1rem;
    text-align: left;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 1rem;
    flex: 1 1 calc(50% - 2rem);
}

.CustomDesign-process-step h3 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0;
    color: var(--secondary-color);
}

.CustomDesign-process-step p {
    margin: 0.5rem 0 0;
}

@media (max-width: 768px) {
    .CustomDesign-flex-container {
        flex-direction: column;
        align-items: stretch;
    }

    .CustomDesign-flex-item {
        flex: 1 1 100%;
        margin: 1rem 0;
    }

    .CustomDesign-grid {
        grid-template-columns: 1fr;
    }

    .CustomDesign-process-step {
        flex: 1 1 100%;
    }
}
