/* Loan and Card Section */
.loan-card-container {
  position: relative;
  text-align: center;
  margin-bottom: -80px; /* Pull up section to overlap */
  z-index: 2;
}

.loan-card-container h3 {
  font-size: 24px;
  color: #21204a;
  margin-bottom: 20px;
}

.card-list {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.loan-card {
  width: 400px;
  height: 160px;
  background-color:var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* Footer Styles */
.footer {
  position: relative;
  background-color: #21204a;
  color: #fff;
  padding: 120px 20px 40px; /* Increase padding for overlap */
  font-family: Arial, sans-serif;
  overflow: hidden;
}

/* Dotted Background Animation */
.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #747879 1px, transparent 1px);
  background-size: 20px 20px;
  opacity: 0.5;
  animation: moveDots 4s linear infinite;
  z-index: 0;
}

@keyframes moveDots {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Footer Content Layout */
.footer-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

}

.footer-section {
  flex: 1;
  min-width: 250px;
  margin: 20px;
}

.footer-logo {
  width: 100px;
background-color: #f8f8f8;
border-radius: 25px;
margin-bottom: 2rem;
}

.footer-section h5 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
  color: #bbb;
  cursor: pointer;
}

.footer-section p {
  color: #bbb;
}

.email-form {
  display: flex;
  align-items: center;
  z-index: 1000;
}

.email-form input {
  width: 70%;
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
}

.email-form button {
  width: 30%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  border-top: 1px solid #333;
  padding-top: 20px;
  z-index: 1000;
}

/* Social Media Icons */
.social-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  z-index: 1000;
}

.social-icons a {
  color: white;
  font-size: 20px;
  margin: 0 10px;
  transition: transform 0.3s;
  z-index: 1000;
}

.social-icons a:hover {
  transform: scale(1.2);
}

/* Footer Copyright */
.footer-bottom p {
  color: #bbb;
}
/* Loan Banner Container */
.loan-banner-container {
  background-color: #f8f8f8;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loan-banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 80%;
  max-width: 1200px;
  padding: 30px 40px;
  position: relative;
}

.loan-banner-left {
  flex: 1;
  padding-right: 20px;
}

.loan-heading {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
  text-transform: uppercase;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
}

.loan-line {
  width: 50px;
  height: 2px;
  background-color: var(--primary-color);
  margin-right: 10px;
}

.loan-banner-left h1 {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.loan-banner-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply-loan-btn {
  background-color: var(--primary-color);
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.apply-loan-btn:hover {
  background-color: var(--button-hover-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .loan-banner-content {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .loan-banner-left {
    padding: 0;
  }

  .loan-banner-right {
    margin-top: 20px;
  }
}