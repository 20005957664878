:root {
  --primary-color: #3681AB;
  --secondary-color: #4b5154;
  --accent-color: #999BBE;
  --background-color: #E8DCF4;
  --text-color: #1d1727;
  --border-color: #CDC6E7;
  --button-hover-color: #AED7ED;
}

.abouthome-container {
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.abouthome-header {
  text-align: center;
  margin-bottom: 2rem;
}

.abouthome-main-heading {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.abouthome-header-description {
  font-size: 1.2rem;
  color: var(--secondary-color);
}

.abouthome-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.abouthome-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 100%;
  margin-left: 9rem;
}

.abouthome-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: #fff;
  transition: transform 0.3s ease;
  position: absolute;
  width: 220px;
}

.abouthome-card:hover {
  transform: scale(1.05);
}

.abouthome-card-icon {
  margin-bottom: 0.5rem;
}

.abouthome-icon {
  font-size: 3rem; /* Larger size for the icon */
}

.abouthome-card-title {
  font-size: 1rem;
  text-align: center;
  color: var(--primary-color);
}

/* Position cards diagonally */
.abouthome-card-1 {
  top: 0;
  left: 0;
}

.abouthome-card-2 {
  top: 120px;
  right: 20px;
}

.abouthome-card-3 {
  top: 240px;
  left: 0;
}

.abouthome-right {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.abouthome-textbox {
  background-color: var(--primary-color);
  padding: 2rem;
  border-radius: 16px 0 0 16px; /* Rounded left side, sharp right side */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 margin-left: 5rem;
  width: 100%;
}

.abouthome-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--background-color);
}

.abouthome-subheading {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: var(--background-color);
}

.abouthome-description {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  color: var(--background-color);
}

.abouthome-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--accent-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.abouthome-button:hover {
  background-color: var(--button-hover-color);
}

/* Responsive design */
@media (max-width: 768px) {
  .abouthome-content {
    flex-direction: column;
  }
  .abouthome-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
    margin-left: 4rem;
  }
  .abouthome-left {
    position: static;
    max-width: 100%;
  }

  .abouthome-card {
    position: static;
    margin-bottom: 1rem;
  }

  .abouthome-right {
    margin-left: -92px;
    margin-top: 2rem;
  }

  .abouthome-textbox {
    padding: 1rem;
  }
}
