/* General Container Styles */
.ReactWebsite-container {
    padding: 40px 20px;
    background-color: #f9f9f9;
    color: #333;
    margin-top: 5.5rem;
  }
  
  /* What Is React Section */
  .ReactWebsite-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .ReactWebsite-content {
    flex: 1;
    padding: 20px;
  }
  
  .ReactWebsite-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #61dafb; /* React Blue */
  }
  
  .ReactWebsite-description {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .ReactWebsite-text {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.6;
    text-align: justify;
  }
  
  .ReactWebsite-list {
    list-style: none;
    padding: 0;
  }
  
  .ReactWebsite-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .ReactWebsite-icon {
    font-size: 1.5rem;
    color: #61dafb;
    margin-right: 10px;
  }
  
  .ReactWebsite-image-container {
    flex: 1;
    text-align: center;
  }
  
  .ReactWebsite-image {
    max-width: 100%;
    border-radius: 10px;
  }
  
  /* React Services Section */
  .ReactServices-section {
    background-color: #ffffff;
    padding: 40px 20px;
    margin-bottom: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .ReactServices-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
    color: #333;
  }
  
  .ReactServices-subtitle {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
    color: #666;
  }
  
  .ReactServices-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .ReactService-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .ReactService-card-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .ReactService-card-text {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }
  
  /* Why Hire React Section */
  .WhyHireReact-container {
    padding: 40px 20px;
    background-color: #ffffff;
    margin-bottom: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .WhyHireReact-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
    color: #333;
  }
  
  .WhyHireReact-subtitle {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
    color: #666;
  }
  
  .WhyHireReact-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .WhyHireReact-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .WhyHireReact-icon {
    font-size: 2rem;
    color: #61dafb;
    margin-bottom: 10px;
  }
  
  .WhyHireReact-card-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .WhyHireReact-card-text {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }
  
  /* Advanced React/MERN Section */
  .ReactAdvanced-section {
    padding: 40px 20px;
    background-color: #f0f4f8;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .ReactAdvanced-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
    color: #333;
  }
  
  .ReactAdvanced-text {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
    color: #666;
  }
  
  .ReactAdvanced-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .ReactAdvanced-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .ReactAdvanced-icon {
    font-size: 2rem;
    color: #61dafb;
    margin-bottom: 10px;
  }
  
  .ReactAdvanced-card-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .ReactAdvanced-card-text {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }
  /* Image Container for React Website */
.ReactWebsite-image-container {
    flex: 1;
    text-align: center;
  }
  
  .ReactWebsite-image {
    max-width: 80%; /* Reduce the width */
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Hover Effect for Image */
  .ReactWebsite-image:hover {
    transform: scale(1.05); /* Slight zoom-in on hover */
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.2);
  }
  
  /* Adjustments for Smaller Screens */
  @media (max-width: 1024px) {
    .ReactWebsite-image {
      max-width: 70%; /* Further reduce width for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .ReactWebsite-image {
      max-width: 60%; /* Further reduce width for small screens */
    }
  }
  
  @media (max-width: 480px) {
    .ReactWebsite-image {
      max-width: 50%; /* Reduce width even more for extra-small screens */
    }
  }
  /* What Is React Section */
.ReactWebsite-content-wrapper {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.ReactWebsite-content {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.ReactWebsite-image-container {
  flex: 1;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.ReactWebsite-image {
  max-width: 80%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effect for Image */
.ReactWebsite-image:hover {
  transform: scale(1.05); /* Slight zoom-in on hover */
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .ReactWebsite-content-wrapper {
    flex-direction: column; /* Stack content vertically */
    text-align: center;
  }

  .ReactWebsite-content,
  .ReactWebsite-image-container {
    width: 100%; /* Full width for smaller screens */
  }

  .ReactWebsite-content {
    margin-bottom: 20px; /* Add spacing between text and image */
  }
}
