

.EmailMarketing-banner {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 2rem 1rem;
  margin-top: 6rem;
}

.EmailMarketing-banner h1 {
  margin: 0;
  font-size: 2.5rem;
}

.EmailMarketing-section {
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.EmailMarketing-section h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.EmailMarketing-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.EmailMarketing-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.EmailMarketing-cards {
  padding: 2rem 1rem;
  background-color: var(--background-color);
}

.EmailMarketing-cardContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.EmailMarketing-card {
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.EmailMarketing-icon {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.EmailMarketing-card h3 {
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.EmailMarketing-card p {
  color: var(--text-color);
  font-size: 0.95rem;
}

@media (min-width: 768px) {
  .EmailMarketing-content {
    flex-direction: row;
  }

  .EmailMarketing-cardContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .EmailMarketing-cardContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}
