.faq-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .faq-content {
    flex: 1;
    padding: 20px;
    max-width: 600px;
  }
  
  .faq-title {
    font-size: 24px;
    color: var(--primary-color);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .faq-item {
    margin-bottom: 15px;
  }
  
  .faq-question {
    font-size: 18px;
    font-weight: bold;
    background-color: var(--primary-color);
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-question span {
    display: inline-block;
  }
  
  .faq-arrow {
    font-size: 16px;
    margin-left: 10px;
  }
  
  .faq-question:hover {
    background-color: var(--button-hover-color);
  }
  
  .faq-answer {
    padding: 10px;
    border-left: 4px solid var(--accent-color);
    background-color: var(--background-color);
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .faq-question.open + .faq-answer {
    display: block;
  }
  
  .faq-image {
    flex: 1;
    text-align: center;
    max-width: 400px;
  }
  
  .home-image {
    width: 100%;
    max-width: 350px;
    animation: float 3s ease-in-out infinite;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @media (max-width: 600px) {
    .faq-title {
      font-size: 20px;
    }
  
    .faq-question {
      font-size: 16px;
    }
  
    .faq-arrow {
      font-size: 14px;
    }
  
    .faq-page {
      flex-direction: column;
    }
  
    .faq-image {
      margin-top: 20px;
    }
  }
  