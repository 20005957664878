

.contact-us-page {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 0 20px;
}

.top-banner {
  background-color: var(--primary-color);
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 7rem;
  
}

.top-banner h1 {
  margin-bottom: 10px;
}

.top-banner p {
  font-size: 1.2rem;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.form-section {
  flex: 1;
  min-width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color); /* Added border */
}

.form-section h2 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-row {
  display: flex;
  gap: 10px;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.contact-form textarea {
  height: 60px;
}

.contact-form button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.contact-form button:hover {
  background-color: var(--button-hover-color);
}

.contact-note {
  margin-top: 10px;
  font-size: 0.9rem;
  color: var(--secondary-color);
}

.info-section {
  flex: 1;
  min-width: 300px;
  height: fit-content;
  background-color: var(--primary-color);
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color); /* Added border */
}

.info-section ol {
  margin: 10px 0;
  padding-left: 20px;
}

.contact-details {
  margin-top: 20px;
}

.map-section {
  margin-top: 20px;
  text-align: center;
}
.small-image {
  width:70%;
  height: 40%;
  object-fit: cover; /* Ensure the image maintains its aspect ratio */
  border-radius: 5px; /* Optional: add rounded corners if needed */
  margin: auto;
  display: block;

}
/* Container styling for phone input */
.react-tel-input {
  width: 100%;
  max-width: 100%;
  position: relative;
  font-family: Arial, sans-serif;
}

/* Input field styling */
.react-tel-input .form-control {
  width: 100%;
  height: 50px;
  padding-left: 50px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: none;
  outline: none;
  transition: border-color 0.3s ease;
}

.react-tel-input .form-control:focus {
  border-color: #007bff;
}


.react-tel-input .flag-dropdown.open {
  background: #e9e9e9;
}



.react-tel-input .country-list .country {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}





/* Placeholder text color */
.react-tel-input .form-control::placeholder {
  color: #999;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .react-tel-input .form-control {
      font-size: 14px;
  }
  
  .react-tel-input .flag-dropdown {
      padding: 0 5px;
  }
}
.contact-note {
  color: #28a7;  /* Green color for success */
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  background-color: #d4edda;  /* Light green background */
  padding: 10px;
  border: 1px solid #c3e6cb;  /* Green border */
  border-radius: 5px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

/* Error message styling */
.error-message {
  color: #dc3545;  /* Red color for error */
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  background-color: #f8d7da;  /* Light red background */
  padding: 10px;
  border: 1px solid #f5c6cb;  /* Red border */
  border-radius: 5px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

/* Form container styling */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}