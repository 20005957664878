/* General Container Styles */
.WordPressBanner-container {
  padding: 40px 20px;
  background-color: #f9f9f9; /* Light background color */
  color: #333; /* Text color */
  margin-top: 5.5rem;
}

/* What Is WordPress Section */
.WordPressBanner-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.WordPressBanner-content {
  flex: 1;
  padding: 20px;
}

.WordPressBanner-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #2a7ae2; /* Primary color */
}

.WordPressBanner-description {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: #444;
}

.WordPressBanner-text {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 15px;
}

.WordPressBanner-list {
  list-style: none;
  padding: 0;
  margin: 15px 0;
}

.WordPressBanner-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1rem;
}

.WordPressBanner-icon {
  margin-right: 10px;
  color: #2a7ae2; /* Icon color */
}

.WordPressBanner-image-container {
  flex: 1;
  text-align: center;
}

.WordPressBanner-image {
  display: inline-block; /* Inline-block se proper align hoga */
  max-width: 90%; /* Responsive image */
  height: auto;
  border-radius: 85px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 130px;
}

/* New Section: WordPress Web Design Services */
.WordPressServices-section {
  padding: 40px 20px;
  background-color: #ffffff; /* White background */
  color: #333;
  border-top: 2px solid #eaeaea;
}

.WordPressServices-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: #2a7ae2; /* Primary color */
}

.WordPressServices-subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 40px;
  text-align: center;
  color: #555;
}

.WordPressServices-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.WordPressService-card {
  background-color: #f9f9f9; /* Light card background */
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.WordPressService-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.WordPressService-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.WordPressService-card-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .WordPressServices-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .WordPressBanner-content-wrapper {
    flex-direction: column;
  }

  .WordPressBanner-image-container {
    margin-top: 20px;
  }

  .WordPressServices-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .WordPressBanner-title {
    font-size: 2rem;
  }

  .WordPressServices-title {
    font-size: 1.8rem;
  }

  .WordPressServices-subtitle {
    font-size: 1rem;
  }
}
/* Container Styles */
.WhyHireWordPress-container {
  padding: 40px 20px;
  background-color: #f9f9f9; /* Light background color */
  color: #333; /* Text color */
}

/* Content Section */
.WhyHireWordPress-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  color: #2a7ae2; /* Primary color */
}

.WhyHireWordPress-subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 25px;
  color: #555;
}

.WhyHireWordPress-text {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 40px;
  text-align: center;
  color: #666;
}

/* Grid Section */
.WhyHireWordPress-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.WhyHireWordPress-card {
  background-color: #ffffff; /* White background */
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.WhyHireWordPress-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.WhyHireWordPress-icon {
  font-size: 2rem;
  color: #2a7ae2; /* Icon color */
  margin-bottom: 15px;
}

.WhyHireWordPress-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.WhyHireWordPress-card-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .WhyHireWordPress-grid {
    grid-template-columns: 1fr;
  }
}
