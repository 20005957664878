

.strategyDevelopment-container {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  margin-top: 8rem;
}

/* Section 1: Banner Section */
.strategyDevelopment-banner {
  text-align: center;
  margin-bottom: 30px;
  justify-content: center;
  justify-items: center;
}

.strategyDevelopment-banner h1 {
  color: var(--primary-color);
  font-size: 2rem;
}

.strategyDevelopment-banner p {
  margin: 10px auto;
  max-width: 800px;
  line-height: 1.6;
}

/* Section 2: Comparison Section */
.strategyDevelopment-comparison {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.strategyDevelopment-box {
  flex: 1;
  max-width: 500px;
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.green-box {
  background-color: #e6f7e6;
  border-left: 6px solid #28a745;
}

.orange-box {
  background-color: #ffe6d4;
  border-left: 6px solid #ff6600;
}

.strategyDevelopment-box h3 {
  color: var(--secondary-color);
  font-size: 1.2rem;
}

.strategyDevelopment-box ul {
  margin-top: 10px;
  padding-left: 20px;
}

.strategyDevelopment-box li {
  margin-bottom: 8px;
  line-height: 1.4;
}

/* Section 3: Call-To-Action */
.strategyDevelopment-cta {
  text-align: center;
  margin: 30px 0;
}

.strategyDevelopment-button {
  background-color: var(--primary-color);
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.strategyDevelopment-button:hover {
  background-color: var(--button-hover-color);
}

/* Section 4: Guidance Section */
.strategyDevelopment-guidance {
  text-align: center;
  margin: 20px 0;
}

.strategyDevelopment-guidance h2 {
  color: var(--secondary-color);
  margin-bottom: 10px;
  font-size: 1.8rem;
}

.strategyDevelopment-guidance p {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Section 5: Growth Section */
.strategyDevelopment-growth {
  text-align: center;
  margin: 20px 0;
}

.strategyDevelopment-growth h2 {
  color: var(--primary-color);
  margin-bottom: 10px;
  font-size: 1.8rem;
}

.strategyDevelopment-growth p {
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

.strategyDevelopment-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .strategyDevelopment-comparison {
    flex-direction: column;
  }

  .strategyDevelopment-box {
    max-width: 100%;
  }
}

  
  /* General Container */
  .strategyDevelopment-container {
    padding: 20px;
    background-color: var(--background-color);
    font-family: Arial, sans-serif;
  }
  
  /* Banner Section */
  .strategyDevelopment-banner h1 {
    color: var(--primary-color);
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .strategyDevelopment-banner p {
    text-align: justify;
    color: var(--text-color);
    margin: 5px 0;
  }
  
  /* Agency Section */
  .strategyDevelopment-agency {
    text-align: center;
    margin: 40px 0;
  }
  
  .strategyDevelopment-agency h2 {
    color: var(--primary-color);
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .strategyDevelopment-agency p {
    color: var(--secondary-color);
    margin-bottom: 30px;
  }
  
  /* Responsive Grid Layout */
  .strategyDevelopment-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  
  @media (max-width: 1024px) {
    .strategyDevelopment-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .strategyDevelopment-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* Grid Item Styling */
  .grid-item {
    text-align: center;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(50px);
    opacity: 0;
    animation: fadeInUp 0.5s ease forwards;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .grid-item:hover {
    transform: translateY(0) scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #fff;
  }
  
  .web-audit-icon {
    background-color: #4caf50;
  }
  
  .ecommerce-icon {
    background-color: #2196f3;
  }
  
  .advertising-icon {
    background-color: #ff9800;
  }
  
  .flexibility-icon {
    background-color: #e91e63;
  }
  
  .seo-icon {
    background-color: #673ab7;
  }
  
  .advertising-strategy-icon {
    background-color: #009688;
  }
  
  /* Animation */
  @keyframes fadeInUp {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  /* Funnel Section */
.strategyDevelopment-funnel {
    padding: 40px 20px;
    background-color: var(--background-color);
    text-align: center;
  }
  
  .funnel-stages {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  .measure-line{
    text-align: center;
    justify-content: center;
    justify-items: center;
    margin-top: 1rem;
  }
  
  .stage {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 1024px) {
    .funnel-stages {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .funnel-stages {
      grid-template-columns: 1fr;
    }
  }