/* CSS for AboutUs component */
:root {
    --primary-color: #3681AB;
    --secondary-color: #4b5154;
    --accent-color: #999BBE;
    --background-color: #E8DCF4;
    --text-color: #1d1727;
    --border-color: #CDC6E7;
    --button-hover-color: #AED7ED;
  }
  
  .about-us-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: Arial, sans-serif;
    margin-top: 6rem;
  }
  
  /* Banner Section */
  .about-banner {
    position: relative;
    height: 350px;
    overflow: hidden;
  }
  
  .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
  }
  
  .banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  .banner-text h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: white;
  }
  
  .banner-text p {
    font-size: 1.5rem;
  }
  
  /* About Section */
  .about-content {
    padding: 20px;
    text-align: center;
  }
  
  .about-description h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  .about-description p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  /* Mission and Vision Section */
  .mission-vision-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
  }
  
  .mission, .vision {
    flex: 1;
    min-width: 300px;
    max-width: 400px;
    text-align: center;
    background-color: var(--accent-color);
    padding: 20px;
    border-radius: 10px;
  }
  
  .mission img, .vision img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .mission h3, .vision h3 {
    margin-top: 15px;
    font-size: 1.5rem;
    color: var(--primary-color);
  }
  
  .mission p, .vision p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* Animation Section */
  .animation-section {
    text-align: center;
    padding: 20px;
  }
  
  .animation-section h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .animated-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .animated-box p {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .mission-vision-section {
      flex-direction: column;
      align-items: center;
    }
  
    .banner-text h1 {
      font-size: 2.5rem;
    }
  
    .banner-text p {
      font-size: 1.2rem;
    }
  }
  