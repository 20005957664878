:root {
    --primary-color: #3681AB;
    --secondary-color: #4b5154;
    --accent-color: #999BBE;
    --background-color: #E8DCF4;
    --text-color: #1d1727;
    --border-color: #CDC6E7;
    --button-hover-color: #AED7ED;
  }
  
  .section-container {
    font-family: Arial, sans-serif;
    background-color: var(--background-color);

    color: var(--text-color);
  }
  
  /* Review Section */
  .review-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    padding: 20px 40px; /* Added left and right padding */
    background-color: var(--primary-color); /* Full width with primary color */
    border-radius: 10px;
  }
  
  .review {
    text-align: center;
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    width: 100%;
    height: 100%;

  }
  
  .review-icon {
    font-size: 50px;
    color: var(--accent-color); /* Colorful icon */
    margin-bottom: 10px;
  }
  
  .review p {
    margin: 5px 0;
    font-size: 1rem;
    font-weight: bold;
    color: var(--secondary-color);
  }
  
  /* About Section */
  .about-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-start;
    margin-top: 30px;
  }
  
  .about-text {
    flex: 1;
    min-width: 300px;
    padding: 10px;
    padding-left: 50px;
  }
  
  .about-text h2 {
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .about-text p {
    margin-bottom: 10px;
    line-height: 1.6;
  }
  
  .about-media {
    flex: 1;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .badge {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .video-container {
    position: relative;
    width: 100%;
    max-width: 300px;
  }
  
  .video-thumbnail {
    display: block; /* Center align */
    margin: 0 auto; /* Horizontally center */
    width: 150%; /* Full width */
    max-width: 1100px; /* Bigger max width */
    height: 25rem; /* Maintain aspect ratio */
    border-radius: 10px; /* Keep smooth edges */
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .play-button:hover {
    background-color: var(--button-hover-color);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .review-section {
      flex-direction: column;
      align-items: center;
    }
    .video-thumbnail {
      width: 100vw;
      border-radius: 10px;
      padding: 1rem;
      border-radius: 8rem;
  }
    .about-section {
      flex-direction: column;
    }
  }
  