:root {
    /* Color Palette */
    --primary-bg: #d8ddea;   /* Main Background */
    --header-bg: #18434e;   /* Header/Footer Background */
    --text-color: #051615;  /* Primary Text */
    --secondary-text: #78949f; /* Secondary Text */
    --button-bg: #18434e;   /* Default Button */
    --button-hover-bg: #051615; /* Hover Button */
    --button-text: #d8ddea; /* Button Text */
    --hover-text: #a1bdd0;  /* Hover Text */
    --border-color: #a1bdd0; /* Section/Card Borders */
  }
  
  .businesses-container {
    text-align: center;
    padding: 40px 20px;
    
  }
  
  .businesses-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--header-bg);
  }
  
  .businesses-description {
    font-size: 1rem;
    color: var(--secondary-text);
    margin-bottom: 0px;
  }
  
  .logos-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .logo-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
    background-color: var(--primary-bg);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 10px;
  }
  
  .company-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .businesses-title {
      font-size: 1.5rem;
    }
  
    .businesses-description {
      font-size: 0.9rem;
    }
  
    .logo-card {
      width: 120px;
      height: 80px;
    }
  }
  
  @media (max-width: 480px) {
    .businesses-title {
      font-size: 1.2rem;
    }
  
    .businesses-description {
      font-size: 0.8rem;
    }
  
    .logo-card {
      width: 100px;
      height: 60px;
    }
  }
  