

.technicalSeo-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.technicalSeo-header {
    text-align: center;
    padding: 20px 10px;
    background-color: var(--primary-color);
    color: white;
}

.technicalSeo-header h1 {
    font-size: 2rem;
    margin: 0;
}

.technicalSeo-header p {
    font-size: 1.2rem;
    margin-top: 10px;
}

.technicalSeo-banner {
    text-align: center;
    padding: 30px 20px;
    background-color: var(--accent-color);
    color: var(--text-color);
}

.technicalSeo-banner h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.technicalSeo-banner p {
    font-size: 1.2rem;
}

.technicalSeo-section {
    margin: 40px 0;
}

.technicalSeo-section h2 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary-color);
}

.technicalSeo-services {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.technicalSeo-service {
    flex: 1 1 calc(33.333% - 40px);
    box-sizing: border-box;
    max-width: 300px;
    background-color: white;
    padding: 20px;
    border: 1px solid var(--accent-color);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.technicalSeo-service h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--secondary-color);
}

.technicalSeo-service p {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--text-color);
}

.technicalSeo-button {
    text-align: center;
    margin: 40px 0;
}

.technicalSeo-button a {
    text-decoration: none;
    background-color: var(--primary-color);
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.technicalSeo-button a:hover {
    background-color: var(--secondary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    .technicalSeo-services {
        flex-direction: column;
        align-items: center;
    }

    .technicalSeo-service {
        max-width: 100%;
    }
}
/* General Styles */
.technical-seo-container {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 0;
    margin: 0;
    margin-top: 6rem;
  }
  
  /* Banner Section */
  .technical-seo-banner {
    background-color: var(--primary-color);
    padding: 60px 20px;
    text-align: center;
    color: #fff;
  }
  
  .technical-seo-banner-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .technical-seo-banner-description {
    font-size: 20px;
    margin-top: 10px;
  }
  
  /* Content Section: Text and Image */
  .technical-seo-content {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    background-color: #f9f9f9;
  }
  
  .technical-seo-text {
    flex: 1;
    padding-right: 20px;
  }
  
  .technical-seo-content-title {
    font-size: 36px;
    color: var(--text-color);
    margin-bottom: 20px;
  }
  
  .technical-seo-content-description {
    font-size: 18px;
    line-height: 1.6;
    color: var(--secondary-color);
    text-align: justify;
  }
  
  .technical-seo-image {
    flex: 1;
    padding-left: 20px;
  }
  
  .technical-seo-image-img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .technical-seo-content {
      flex-direction: column;
      text-align: center;
    }
  
    .technical-seo-text,
    .technical-seo-image {
      flex: 1 1 100%;
      padding: 0;
    }
  
    .technical-seo-content-title {
      font-size: 30px;
    }
  
    .technical-seo-image-img {
      width: 80%;
      margin: 0 auto;
    }
  }
  