
  .policy-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10rem;
  }
  
  .policy-section {
    margin-bottom: 40px;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--background-color);
  }
  
  .policy-section h1 {
    color: var(--primary-color);
    font-size: 2rem;
    margin-bottom: 16px;
  }
  
  .policy-section h2 {
    color: var(--secondary-color);
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .policy-section p,
  .policy-section ul {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .policy-section ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .policy-section a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  .policy-section a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .policy-container {
      padding: 15px;
    }
  
    .policy-section h1 {
      font-size: 1.8rem;
    }
  
    .policy-section h2 {
      font-size: 1.3rem;
    }
  
    .policy-section p,
    .policy-section ul {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .policy-container {
      padding: 10px;
    }
  
    .policy-section h1 {
      font-size: 1.5rem;
    }
  
    .policy-section h2 {
      font-size: 1.2rem;
    }
  
    .policy-section p,
    .policy-section ul {
      font-size: 0.85rem;
    }
  }
  .policy-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: 8rem;
  }
  
  .policy-section {
    margin-bottom: 40px;
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    background-color: var(--background-color);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .policy-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .policy-section h1 {
    color: var(--primary-color);
    font-size: 2.2rem;
    margin-bottom: 18px;
  }
  
  .policy-section h2 {
    color: var(--secondary-color);
    font-size: 1.6rem;
    margin-top: 24px;
    margin-bottom: 12px;
  }
  
  .policy-section p,
  .policy-section ul {
    font-size: 1rem;
    line-height: 1.8;
  }
  
  .policy-section ul {
    padding-left: 25px;
    list-style-type: disc;
  }
  
  .policy-section a {
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: none;
  }
  
  .policy-section a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .policy-container {
      padding: 15px;
    }
  
    .policy-section h1 {
      font-size: 1.8rem;
    }
  
    .policy-section h2 {
      font-size: 1.3rem;
    }
  
    .policy-section p,
    .policy-section ul {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .policy-container {
      padding: 10px;
    }
  
    .policy-section h1 {
      font-size: 1.5rem;
    }
  
    .policy-section h2 {
      font-size: 1.2rem;
    }
  
    .policy-section p,
    .policy-section ul {
      font-size: 0.85rem;
    }
  }
  