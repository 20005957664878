/* General Styles */
.carousel-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Occupies full viewport height on larger screens */
  overflow: hidden;
  background: var(--background-color); /* Ensure a fallback background color is set */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5.9rem; /* Adjusted to account for header/nav spacing */
}

.carousel-video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents video overflow */
}

.carousel-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video scales proportionally */
}

/* Controls */
.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
 /* Ensures controls don't block interaction with the video */
}

.carousel-button {
  pointer-events: all; /* Re-enable interaction for buttons */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: none;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker background on hover */
}

/* Media Queries for Responsiveness */

/* Medium devices (tablets and smaller screens) */
@media (max-width: 768px) {
  .carousel-container {
    height: 80vh; /* Reduce height for smaller screens */

  }
  .serviceshome-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 6rem; */
}

.serviceshome-section {
  /* padding: 4rem 1rem; */
  background-color: var(--background-color);
  text-align: center;
  position: relative;
  margin-bottom: 2.5rem;
}
  .carousel-video {
    height: auto; /* Maintain aspect ratio on tablets */
  }

  .carousel-button {
    font-size: 1.5rem; /* Adjust button size */
    padding: 0.4rem 0.8rem;
    display: none;
  }

  .carousel-controls {
    width: 95%; /* Adjust controls width */
  }
}

/* Small devices (mobile phones and very small screens) */
@media (max-width: 480px) {
  .carousel-container {
    height: 31vh; /* Further reduce height for very small screens */
  }

  .carousel-video {
    height: auto;
    max-height: 70vh; /* Limit video height to container */
  }

  .carousel-button {
    font-size: 1.2rem; /* Smaller button size for mobiles */
    padding: 0.3rem 0.6rem;
  }

  .carousel-controls {
    width: 90%; /* Reduce controls width for smaller devices */
  }
}

/* Extra Small Devices (Under 320px) */
@media (max-width: 320px) {
  .carousel-container {
    height: 60vh; /* Adjust height for very small devices */
  }

  .carousel-button {
    font-size: 1rem; /* Further reduce button size */
    padding: 0.2rem 0.5rem;
  }
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.loader-image {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.carousel-video-wrapper.hidden {
  display: none;
}
.carousel-video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-video-wrapper.hidden {
  display: none;
}

.carousel-video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.loader-image {
  width: 50px;
  height: 50px;
}
