.blog-page {
  padding: 20px;
  margin-top: 6rem;
}

.banner-section {
  text-align: center;
  background-color: var(--primary-color);
  color: white;
  padding: 40px ;
  border-radius: 8px;
}

.filter-section {
  margin: 20px 0;
  text-align: justify;
}

.filter-section label {
  margin-right: 10px;
  color: var(--text-color);
}

.filter-section select {
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: white;
}

.recent-work {
  text-align: center;
}/* Parent container to make all cards same height */
.projects-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: stretch; /* Ensure all items have the same height */
  
}

/* Ensuring all cards are equal height */
.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;  /* Fixed width */
  height: 450px; /* Fixed height */
  padding: 15px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Make sure images fit properly and are smaller */
.project-image {
  max-width: 100%;
  height: 120px; /* Fixed small height */
  object-fit: contain; /* Ensures the image fits without cutting */
  border-radius: 10px;
}

/* Content should take available space */
.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

/* Keep the button always at the bottom */
.read-more {
  margin-top: auto;
  background-color: #2a75bb;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.read-more:hover {
  background-color: #1d5a91;
}
