
  
  /* Instagram Marketing Page */
  .instagram-marketing-page {
    font-family: Arial, sans-serif;
    color: var(--secondary-color);
    background-color: var(--background-color);
    margin-top: 6rem;
  }
  
  /* Banner Section */
  .instagram-marketing-page-banner {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    padding: 50px;
  }
  
  .instagram-marketing-page-banner h1 {
    font-size: 36px;
  }
  
  .instagram-marketing-page-banner p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  /* Text and Image Section */
  .instagram-marketing-page-text-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
  }
  
  .instagram-text-image-text {
    width: 50%;
  }
  
  .instagram-text-image-text h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .instagram-text-image-text p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .instagram-text-image-text ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .instagram-text-image-image img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
  }
  
  /* Why Choose Section */
  .instagram-marketing-page-why-choose {
    background-color: var(--text-color);
    color: white;
    padding: 40px;
  }
  
  .instagram-marketing-page-why-choose h2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .instagram-whychoose-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .instagram-whychoose-item {
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .instagram-whychoose-item img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .instagram-whychoose-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .instagram-whychoose-item p {
    font-size: 14px;
  }
  
  /* Instagram Marketing Services Section */
  .instagram-marketing-page-services {
    padding: 50px;
    text-align: center;
  }
  
  .instagram-marketing-page-services h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .instagram-marketing-page-services p {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .instagram-services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .instagram-service-item {
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: white;
  }
  
  .instagram-service-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .instagram-service-item p {
    font-size: 14px;
  }
  
  /* Instagram Marketing Team Section */
  .instagram-marketing-page-team {
    padding: 50px;
    text-align: center;
  }
  
  .instagram-marketing-page-team h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  
  .instagram-team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .instagram-team-item {
    background-color: var(--primary-color);
    color: white;
    padding: 20px;
    border-radius: 10px;
  }
  
  .instagram-team-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .instagram-team-item p {
    font-size: 14px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .instagram-marketing-page-services h2, .instagram-marketing-page-team h2 {
      font-size: 24px;
    }
  
    .instagram-services-grid, .instagram-team-grid {
      grid-template-columns: 1fr 1fr; /* 2 items per row */
    }
  
    .instagram-service-item, .instagram-team-item {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .instagram-services-grid, .instagram-team-grid {
      grid-template-columns: 1fr; /* 1 item per row */
    }
  }
  /* Text and Image Section */
.instagram-marketing-page-text-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  flex-wrap: wrap; /* Allow items to wrap for smaller devices */
}

.instagram-text-image-text {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
}

.instagram-text-image-image {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
}

.instagram-text-image-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  display: block;
  margin: 0 auto; /* Image ko center karega */
}

/* Responsive Design */
@media (max-width: 768px) {
  .instagram-marketing-page-text-image {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .instagram-text-image-text,
  .instagram-text-image-image {
    width: 100%; /* Full width for both text and image */
    text-align: center;
  }

  .instagram-text-image-text {
    margin-bottom: 20px; /* Add spacing between text and image */
  }
}
