/* General Page Styling */
.linkedin-marketing-page {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: Arial, sans-serif;
    margin-top: 6rem;
  }
  
  .linkedin-marketing-page-banner {
    text-align: center;
    background: var(--primary-color);
    color: white;
    padding: 50px 0;
  }
  
  .linkedin-marketing-page-banner h1 {
    font-size: 3rem;
  }
  
  .linkedin-marketing-page-text-image {
    display: flex;
    justify-content: space-between;
    padding: 30px;
  }
  
  .text-image-text {
    flex: 1;
    margin-right: 20px;
  }
  
  .text-image-image img {
    display: block;
    margin: -20px auto 0 auto; /* Top margin negative karke upar le jao */
    width: 100%;
    height: auto;
  }
  
  .linkedin-marketing-page-why-choose,
  .linkedin-marketing-page-services,
  .linkedin-marketing-page-team {
    padding: 50px 30px;
    background-color: var(--accent-color);
  }
  
  .linkedin-marketing-page-why-choose h2,
  .linkedin-marketing-page-services h2,
  .linkedin-marketing-page-team h2 {
    color: var(--primary-color);
    font-size: 2rem;
  }
  
  .linkdin-why-choose-grid,
  .linkdin-services-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
  
  .linkdin-why-choose-item,
  .linkdin-service-item {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
  }
  
  .linkdin-why-choose-item h3,
  .linkdin-service-item h3 {
    color: var(--primary-color);
  }
  
  .linkedin-marketing-page-cta {
    text-align: center;
    padding: 50px 0;
  }
  
  .linkedin-marketing-page-cta h2 {
    font-size: 2.5rem;
    color: var(--primary-color);
  }
  
  .linkedin-marketing-page-cta button {
    background-color: var(--primary-color);
    color: white;
    padding: 15px 30px;
    font-size: 1.25rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .linkedin-marketing-page-cta button:hover {
    background-color: var(--secondary-color);
  }
  
  .linkedin-marketing-page-additional {
    background-color: var(--background-color);
    padding: 40px;
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    .linkedin-marketing-page-text-image {
      flex-direction: column;
    }
  
    .linkdin-why-choose-grid,
    .linkdin-services-grid {
      grid-template-columns: 1fr 1fr;
    }
  
    .linkedin-marketing-page-cta button {
      width: 100%;
      font-size: 1.5rem;
    }
  }
  .linkedin-whychoose{
    color: var(--primary-color);
    text-align: center;
  }