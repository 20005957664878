/* Main Container */
.vlog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  color: #333;
  margin-top: 8rem;
  line-height: 1.6;
}

/* Title Styling */
.vlog-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
  color: #2c3e50;
  border-bottom: 3px solid #3498db;
  padding-bottom: 15px;
}

/* Blog Sections */
.vlog-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 50px;
}

.vlog-section.reverse {
  flex-direction: row-reverse;
}

/* Content Styling */
.vlog-content {
  flex: 1;
  padding: 20px;
}

.vlog-content h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 15px;
  border-left: 5px solid #3498db;
  padding-left: 15px;
}

.vlog-content p {
  font-size: 1.2rem;
  color: #555;
}

.vlog-content ul,
.vlog-content ol {
  padding-left: 20px;
  font-size: 1.1rem;
  color: #555;
}

.vlog-content li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.vlog-content li::before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #3498db;
  font-weight: bold;
}

/* Image Styling */
.vlog-image {
  flex: 1;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;
  object-fit: cover;
  
  transition: transform 0.3s ease-in-out;
}

.vlog-image:hover {
  transform: scale(1.05);
}

/* Subtitle */
.vlog-subtitle {
  font-size: 1.8rem;
  font-weight: bold;
  color: #34495e;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

/* Links Styling */
a {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .vlog-section {
    flex-direction: column;
    text-align: center;
    display: block;
  }

  .vlog-content {
    padding: 20px 10px;
  }

  .vlog-image {
    max-width: 100%;
  }

  .vlog-title {
    font-size: 2rem;
  }

  .vlog-content h2 {
    font-size: 1.8rem;
  }

  .vlog-subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .vlog-container {
    padding: 20px 15px;
  }

  .vlog-title {
    font-size: 1.8rem;
  }

  .vlog-content h2 {
    font-size: 1.6rem;
  }

  .vlog-content p {
    font-size: 1rem;
  }

  .vlog-subtitle {
    font-size: 1.4rem;
  }
}

@media (max-width: 480px) {
  .vlog-container {
    padding: 15px 10px;
  }

  .vlog-title {
    font-size: 1.5rem;
  }

  .vlog-content h2 {
    font-size: 1.4rem;
  }

  .vlog-content p {
    font-size: 0.9rem;
  }

  .vlog-subtitle {
    font-size: 1.2rem;
  }
}
