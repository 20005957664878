/* Root Variables */
  
  /* General Container */
  .iosdevelopment-container {
    font-family: Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 20px;
    margin-top: 8rem;
  }
  
  /* Banner Section */
  .iosdevelopment-banner {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .iosdevelopment-banner h1 {
    color: var(--primary-color);
    font-size: 2rem;
  }
  
  .iosdevelopment-banner p {
    margin: 10px auto;
    max-width: 800px;
    line-height: 1.6;
  }
  
  /* Benefits Section */
  .iosdevelopment-benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .iosdevelopment-box {
    flex: 1;
    max-width: 500px;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
  }
  
  .green-box {
    background-color: #e6f7e6;
    border-left: 6px solid #28a745;
  }
  
  .orange-box {
    background-color: #ffe6d4;
    border-left: 6px solid #ff6600;
  }
  
  .iosdevelopment-box h3 {
    color: var(--secondary-color);
    font-size: 1.2rem;
  }
  
  .iosdevelopment-box ul {
    margin-top: 10px;
    padding-left: 20px;
  }
  
  .iosdevelopment-box li {
    margin-bottom: 8px;
    line-height: 1.4;
  }
  
  /* Features Section */
  .iosdevelopment-features {
    text-align: center;
    margin: 20px 0;
  }
  
  .iosdevelopment-features h2 {
    color: var(--secondary-color);
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
  
  .iosdevelopment-features p {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  .iosdevelopment-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  
  @media (max-width: 1024px) {
    .iosdevelopment-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .iosdevelopment-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .grid-item {
    text-align: center;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .grid-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #fff;
  }
  
  .custom-app-icon {
    background-color: #4caf50;
  }
  
  .ui-ux-icon {
    background-color: #2196f3;
  }
  
  .performance-icon {
    background-color: #ff9800;
  }
  
  .integration-icon {
    background-color: #673ab7;
  }
  
  .maintenance-icon {
    background-color: #e91e63;
  }
  
  .scalability-icon {
    background-color: #009688;
  }
  
  /* Process Section */
  .iosdevelopment-process {
    text-align: center;
    margin: 20px 0;
  }
  
  .process-stages {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  
  @media (max-width: 1024px) {
    .process-stages {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .process-stages {
      grid-template-columns: 1fr;
    }
  }
  
  .stage {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .primary-color {
    color: var(--primary-color);
  }
  
  .measure-line {
    text-align: center;
    margin-top: 1rem;
  }
  