/* Container */
.ourservices-container {
    padding: 4rem 5%;
    background-color: white;
    font-family: "Arial", sans-serif;
    text-align: center;
    color: var(--text-color);
  }
  
  .ourservices-title {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .ourservices-description {
    font-size: 1rem;
    color: var(--secondary-color);
    margin-bottom: 2.5rem;
    text-align: justify;
  }
  
  /* Carousel */
  .ourservices-carousel {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .ourservices-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust based on cardsPerView */
    gap: 2rem;
    overflow: hidden;
    width: 100%;
  }
  
  /* Arrows */
  .ourservices-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-color);
    border: none;
    color: white;
    font-size: 2rem;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .left-arrow {
    left: -2rem;
  }
  
  .right-arrow {
    right: -2rem;
  }
  
  .ourservices-arrow:hover {
    background: var(--button-hover-color);
  }
  
  /* Card */
  .ourservices-card {
    background: #fff;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .ourservices-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  .ourservices-card-image {
    width: 100%; /* Ensures the image spans the full width of the card */
    height: auto !important; /* Maintains a consistent height for all images */
    object-fit: contain; /* Forces the image to fill the space, may stretch if aspect ratio is different */
    border-top-left-radius: 10px; /* Matches the card's border radius */
    border-top-right-radius: 10px; /* Matches the card's border radius */
  }
  
  
  
  
  
  /* Card Content */
  .ourservices-card-content {
    padding: 1.5rem;
  }
  
  .ourservices-card-icon {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .ourservices-card-title {
    font-size: 1.5rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
  }
  
  .ourservices-card-description {
    font-size: 1rem;
    color: var(--secondary-color);
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .ourservices-title {
      font-size: 2rem;
    }
    .left-arrow {
      left: -1rem;
    }
    
    .right-arrow {
      right: -1rem;
    }
    .ourservices-description {
      font-size: 1rem;
      line-height: 1.6;
      text-align: justify;
    
    }
    
    .ourservices-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
    }
    
    @media (min-width: 768px) {
      .ourservices-container {
        padding: 40px;
      }
    
      .ourservices-description {
        font-size: 1.1rem;
      }
    }
    
    @media (min-width: 1024px) {
      .ourservices-container {
        max-width: 900px;
        margin: 0 auto;
      }
    
      .ourservices-description {
        font-size: 1.2rem;
        text-align: left;
      }
    }
    
  
    .ourservices-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .ourservices-card-title {
      font-size: 1.25rem;
    }
  
    .ourservices-card-description {
      font-size: 0.9rem;
    }
  }
  