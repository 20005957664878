/* CSS for Enterprise Marketing Page */


.enterprise-marketing-page {
  font-family: Arial, sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
  margin-top: 6rem;
}

/* Banner Section */
.enterprise-marketing-page-banner {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 50px;
}

.enterprise-marketing-page-banner h1 {
  font-size: 36px;
}

.enterprise-marketing-page-banner p {
  font-size: 18px;
  margin: 10px 0;
}

.banner-input {
  margin-top: 20px;
}

.banner-input input {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.banner-input button {
  padding: 10px 20px;
  background-color: var(--button-hover-color);
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.banner-input button:hover {
  background-color: var(--primary-color);
}

/* Text and Image Section */
.enterprise-marketing-page-text-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
}

.text-image-text {
  width: 50%;
}

.text-image-text h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.text-image-text p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
  text-align: justify;
}

.text-image-text ul {
  list-style-type: disc;
  margin-left: 20px;
}

.text-image-text ul li {
  margin-bottom: 5px;
}

.text-image-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

/* Why Choose Section */
.enterprise-marketing-page-why-choose {
  color: white;
  padding: 40px;
}

.enterprise-marketing-page-why-choose h2 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}

.why-choose-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.why-choose-item {
  background-color: var(--primary-color);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.why-choose-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.why-choose-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.why-choose-item p {
  font-size: 14px;
}

/* Case Studies Section */
.enterprise-marketing-page-case-studies {
  padding: 40px;
}

.enterprise-marketing-page-case-studies h2 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}

.case-studies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.case-study-card {
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.case-study-card img {
  width: 100%;
}

.case-study-card h3 {
  font-size: 18px;
  margin: 10px;
}

.case-study-card p {
  font-size: 14px;
  margin: 5px 10px;
}

/* Call to Action Section */
.enterprise-marketing-page-cta {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 50px;
}

.enterprise-marketing-page-cta h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.enterprise-marketing-page-cta p {
  font-size: 16px;
  margin-bottom: 20px;
}

.enterprise-marketing-page-cta button {
  padding: 10px 20px;
  background-color: var(--button-hover-color);
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
}

.enterprise-marketing-page-cta button:hover {
  background-color: var(--primary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .enterprise-marketing-page-text-image {
    flex-direction: column;
    align-items: center;
  }

  .text-image-text {
    width: 100%;
    text-align: center;
  }

  .text-image-image img {
    width: 100%;
  }
}
