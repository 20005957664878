


.SEOcontentwriting-banner {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 2rem 1rem;
  margin-top: 6rem;
}

.SEOcontentwriting-banner h1 {
  margin: 0;
  font-size: 2.5rem;
}

.SEOcontentwriting-section {
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.SEOcontentwriting-section h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.SEOcontentwriting-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.SEOcontentwriting-row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.SEOcontentwriting-column {
  flex: 1;
  padding: 1rem;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.SEOcontentwriting-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

@media (min-width: 768px) {
  .SEOcontentwriting-row {
    flex-direction: row;
  }
}
