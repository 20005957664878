/* General Styling */
.leadgeneration-page {
    font-family: Arial, sans-serif;
    margin-top: 4rem;
  }
  
  .leadgeneration-banner {
    background: var(--primary-color);
    color: white;
    text-align: center;
    padding: 50px 20px;
  }
  
  .leadgeneration-banner h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .leadgeneration-banner p {
    font-size: 18px;
    margin-top: 0;
  }
  
  /* Text and Image Section */
  .leadgeneration-text-image {
    display: flex;
    justify-content: space-between;
    padding: 40px 20px;
    flex-wrap: wrap;
  }
  
  .leadgeneration-text {
    flex: 1;
    margin-right: 10px;
  }
  
  .leadgeneration-text h2 {
    font-size: 28px;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .leadgeneration-text ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .leadgeneration-text li {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .leadgeneration-image {
    flex: 1;
    max-width: 350px;
    margin-left: 20px;
    align-items: center;
  }
  
  .leadgeneration-image img {
    width: 100%;
    height: 350px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  /* Why Choose Section */
  .leadgeneration-why-choose {
    background: #f7f7f7;
    padding: 60px 20px;
    text-align: center;
  }
  
  .leadgeneration-whychoose-title {
    font-size: 30px;
    color: var(--primary-color);
    margin-bottom: 40px;
  }
  
  .leadgeneration-whychoose-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .leadgeneration-whychoose-item {
    background: var(--primary-color);
    padding: 20px;
    border-radius: 8px;
    color: white;
    text-align: center;
    flex: 1 1 calc(25% - 20px);
    min-width: 250px;
  }
  
  .leadgeneration-whychoose-item h3 {
    font-size: 20px;
    margin-top: 15px;
  }
  
  .leadgeneration-whychoose-item p {
    font-size: 14px;
    margin-top: 10px;
  }
  
  /* Lead Nurturing Section */
  .leadgeneration-nurturing {
    padding: 40px 20px;
  }
  
  .leadgeneration-nurturing-title {
    font-size: 30px;
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 30px;
  }
  
  .leadgeneration-nurturing-steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .leadgeneration-nurturing-step h3 {
    font-size: 22px;
    color: var(--primary-color);
  }
  
  .leadgeneration-nurturing-step p {
    font-size: 16px;
    margin-top: 10px;
  }
  
  /* Additional Lead Generation Section */
  .leadgeneration-additional-section {
    background: #eaeaea;
    padding: 60px 20px;
    text-align: justify;
  }
  
  .leadgeneration-additional-section h2 {
    font-size: 30px;
    color: var(--primary-color);
    margin-bottom: 30px;
  }
  
  .leadgeneration-additional-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .leadgeneration-additional-content ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .leadgeneration-additional-content li {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .leadgeneration-text-image {
      flex-direction: column;
      text-align: center;
    }
  
    .leadgeneration-image {
      max-width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  
    .leadgeneration-text h2 {
      font-size: 24px;
    }
  
    .leadgeneration-text li {
      font-size: 14px;
    }
  
    .leadgeneration-whychoose-grid {
      justify-content: center;
    }
  
    .leadgeneration-whychoose-item {
      flex: 1 1 calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .leadgeneration-whychoose-item {
      flex: 1 1 100%;
    }
  }
  