
  
  .copyrightservices {
    font-family: Arial, sans-serif;
    color: var(--text-color);
    background-color: var(--background-color);
    padding: 20px;
    margin-top:4.5rem;
  }
  
  .copyrightservices__banner {
    text-align: center;
    padding: 50px 20px;
    background-color: var(--primary-color);
    color: white;
  }
  
  .copyrightservices__banner h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .copyrightservices__banner p {
    font-size: 1.2rem;
  }
  
  .copyrightservices__introduction {
    margin: 40px auto;
    max-width: 800px;
    text-align: center;
  }
  
  .copyrightservices__introduction h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .copyrightservices__introduction p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
    text-align: justify;
  }
  
  .copyrightservices__benefits {
    margin: 40px auto;
    max-width: 800px;
  }
  
  .copyrightservices__benefits h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .copyrightservices__benefits ul {
    list-style: none;
    padding: 0;
  }
  
  .copyrightservices__benefits li {
    background: var(--accent-color);
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
  }
  
  .copyrightservices__cards {
    margin: 40px auto;
    max-width: 1200px;
  }
  
  .copyrightservices__cards h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .copyrightservices__cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .copyrightservices__card {
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    flex: 1 1 calc(33% - 40px);
    max-width: calc(33% - 40px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .copyrightservices__card:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
    transition: all 0.3s ease;
  }
  
  .copyrightservices__icon {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .copyrightservices__card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .copyrightservices__card p {
    font-size: 0.9rem;
    line-height: 1.4;
  }
  
  .copyrightservices__additional {
    text-align: center;
    margin: 40px auto;
    padding: 20px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 10px;
  }
  
  .copyrightservices__additional h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .copyrightservices__additional p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .copyrightservices__cta {
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .copyrightservices__cta:hover {
    background-color: var(--button-hover-color);
    transition: background-color 0.3s ease;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .copyrightservices__cards-container {
      flex-direction: column;
    }
  
    .copyrightservices__card {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  