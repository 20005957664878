/* General container styling */
.industryweserve-container {
  text-align: center;
  padding: 2rem 2rem;
  background-color: var(--background-color);
  overflow: hidden;
}

/* Heading styling */
.industryweserve-header {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}
/* Description styling */
.industryweserve-description {
  font-size: 1rem;
  color: var(--secondary-color);
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
}

/* Scrolling container */
.industryweserve-scroll-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  width: 100%;
}

/* Track for continuous scrolling */
.industryweserve-scroll-track {
  display: flex;
  gap: 15px;
  animation: scroll-left 30s linear infinite;
  width: fit-content;
}

/* Reverse direction for the second row */
.industryweserve-scroll-track.reverse {
  animation: scroll-right 30s linear infinite;
}

/* Keyframes for scrolling left */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Keyframes for scrolling right */
@keyframes scroll-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Flip box styling */
.industryweserve-flip-box {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  perspective: 1000px;
}

.industryweserve-flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.industryweserve-flip-box-front,
.industryweserve-flip-box-back {
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
}

/* Front side styling */
.industryweserve-flip-box-front {
  background-color: var(--secondary-color);
}

/* Back side styling */
.industryweserve-flip-box-back {
  background-color: var(--tertiary-color);
  transform: rotateY(180deg);
}

/* Adjust card icon */
.industryweserve-card-icon {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Flip animation */
.industryweserve-flip-box:hover .industryweserve-flip-box-inner {
  transform: rotateY(180deg);
}

/* Responsive adjustments for all devices */
@media (max-width: 1200px) {
  .industryweserve-header {
    font-size: 2.2rem;
  }

  .industryweserve-flip-box {
    width: 180px;
    height: 180px;
  }

  .industryweserve-card-icon {
    font-size: 2.8rem;
  }
}

@media (max-width: 992px) {
  .industryweserve-header {
    font-size: 2rem;
  }

  .industryweserve-flip-box {
    width: 160px;
    height: 160px;
  }

  .industryweserve-card-icon {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .industryweserve-header {
    font-size: 1.8rem;
  }

  .industryweserve-flip-box {
    width: 140px;
    height: 140px;
  }

  .industryweserve-card-icon {
    font-size: 2rem;
  }

  .industryweserve-scroll-track {
    animation-duration: 45s; /* Slower animation for smaller screens */
  }
}

@media (max-width: 576px) {
  .industryweserve-header {
    font-size: 1.6rem;
  }

  .industryweserve-flip-box {
    width: 120px;
    height: 120px;
  }

  .industryweserve-card-icon {
    font-size: 1.8rem;
  }

  .industryweserve-scroll-track {
    animation-duration: 50s; /* Slower animation for very small screens */
  }
}
