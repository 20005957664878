:root {
  --primary-color: #3681AB;
  --secondary-color: #4b5154;
  --accent-color: #999BBE;
  --background-color: #E8DCF4;
  --text-color: #1d1727;
  --border-color: #CDC6E7;
  --button-hover-color: #AED7ED;
}

.services-container {
  padding: 40px 20px;
  background-color: var(--background-color);
  text-align: center;
  font-family: Arial, sans-serif;
  text-decoration: none;
}

.services-container h2 {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 20px;
}

.services-container p {
  color: var(--text-color);
  font-size: 1rem;
  margin-bottom: 40px;
  line-height: 1.6;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* 3 cards per row for larger screens */
  gap: 30px; /* Equal spacing between service cards */
  justify-items: center; /* Centers cards within their row */
  text-decoration: none;
}

.service-item {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
  transform: translateY(-10px); /* Lift effect on hover */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.service-icon-container {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--primary-color), var(--button-hover-color));
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover .service-icon-container {
  transform: scale(1.1); /* Slight zoom on hover */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 40px;
  color: white;
  transition: transform 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.service-item:hover .service-icon {
  transform: rotate(20deg); /* Adds subtle rotation on hover */
  color: white;
}

.service-item h3 {
  color: var(--secondary-color);
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.service-item p {
  color: var(--text-color);
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row for medium screens */
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr; /* 1 card per row for small screens */
  }

  .services-container h2 {
    font-size: 1.5rem;
  }

  .services-container p {
    font-size: 0.9rem;
  }

  .service-item {
    padding: 15px;
  }

  .service-icon {
    font-size: 35px;
    text-decoration: none;
  }

  .service-item h3 {
    font-size: 1rem;
    text-decoration: none;
  }

  .service-item p {
    font-size: 0.8rem;
    text-decoration: none;
  }
}
